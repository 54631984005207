export const typesets = {
    title: 'type-title',
    titleEmphasis: 'type-title-emphasis',
    subtitle: 'type-subtitle',
    heading: 'type-heading',
    subheading: 'type-subheading',
    paragraph: 'type-paragraph',
    buttonLabel: 'type-button-label',
    emphasis: 'type-emphasis',
    link: 'type-link',
    linkReverse: 'type-link-reverse',
    quote: 'type-quote',
};

export const getTypesetClass = (typeset?: keyof typeof typesets) => (typeset ? typesets[typeset] : '');
